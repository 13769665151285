import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { Link } from "react-router-dom";
import favicon from "../../media/icon.png";

const pages = [
  {
    page: 'Maison',
    url: '/'
  },
  {
    page: 'Jeu',
    url: '/games'
  },
  {
    page: 'Contact',
    url: '/contact'
  }];

function HeaderNav() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static" sx={{ background: "transparent", boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* mobile navigation START */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            
          <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
                color: "#111"
              }}
            >
              {pages.map(({ page, url, index }) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" sx={{ color: "#111" }}>
                    <Link
                      key={index}
                      to={url}
                      relative="path"
                      onClick={handleCloseNavMenu}
                      sx={{ color: 'white', display: 'block', padding: "0 2em", color: "#111111", '&:hover': { textDecoration: "underline" } }}
                    >
                      {page}
                    </Link>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* mobile navigation END */}

          {/* desktop navigation START */}
          <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Link
              key="logo"
              to="/"
              relative="path"
              sx={{ color: "#fff", textDecoration: "none" }}
            >
              <img width="30" src={favicon} alt="icon" />
              aris Lucky Play
            </Link>

            <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: "2.5em" }}>
              {pages.map(({ page, url, index }) => (
                <Link
                  key={index}
                  to={url}
                  relative="path"
                  onClick={handleCloseNavMenu}
                  sx={{ color: 'white', display: 'block', padding: "0 2em", color: "#111111", '&:hover': { textDecoration: "underline" } }}
                >
                  {page}
                </Link>
              ))}
            </Box>
          </Box>

          {/* desktop navigation END  */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default HeaderNav;