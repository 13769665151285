import React from 'react'
import { Typography } from "@mui/material";

function HeroText() {
    return (
       <>
        <Typography component="h1" sx={{fontSize: "42px"}}>
        Paris Lucky Play <br/> Où le Plaisir et l'Excitation Vous Attendent !
        </Typography>
        <Typography component="p" sx={{ fontSize: "16px", mb: "4em"}}>
        Êtes-vous prêt à embarquer dans une aventure de jeu exaltante comme aucune autre? Ne cherchez pas plus loin, car vous êtes arrivé sur Paris Lucky Play, votre destination unique pour un monde de jeux en ligne gratuits et d'expériences de casino palpitantes, le tout sans avoir besoin d'argent réel.
        </Typography>
       </>
    )
}

export default HeroText
