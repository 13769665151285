import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function AgeModal() {
  const [showAgeRestrictionModal, setShowAgeRestrictionModal] = useState(false);
  const [ageAccepted, setAgeAccepted] = useState(false);

  useEffect(() => {
    const ageAccepted = localStorage.getItem("ageAccepted");
    !ageAccepted ? setShowAgeRestrictionModal(true) : setAgeAccepted(true);
    console.log('ageAccepted', ageAccepted)
  }, []);

  const handleAge = () => {
    localStorage.setItem("ageAccepted", true);
    setAgeAccepted(true);
    setShowAgeRestrictionModal(false);
  };

  return (
    <>
      {showAgeRestrictionModal && !ageAccepted && (
        <div id="age-verify-popup">
          <div className="window">
            <p>
            Les Services sont destinés aux utilisateurs âgés de <b> 18 ans ou plus</b>. En accédant et en utilisant nos Services, vous confirmez que vous remplissez cette condition d’âge.
            </p>

            <h2>As-tu plus de 18 ans?</h2>
            <button onClick={handleAge} className="yes">
            Oui je suis.
            </button>
            <Link to="policy" className="privacy">
            Apprendre encore plus
            </Link>
          </div>
        </div>
      )}
    </>
  );
}