import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Typography } from "@mui/material";

import img from "../../media/Vector.png";

const navItems = [
  { title: "Maison", src: "/" },
  { title: "Politique", src: "/policy" },
  { title: "Contact", src: "/contact" },
];
const copyrightText = "parisluckyplay.com 2024 © Tous droits réservés.";

function Footer() {
  return (
    <footer className="footer">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: "0.5em 2em 2em",
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography component="span"
            sx={{
              color: "#fff",
              fontSize: "18px",
              textAlign: { xs: "center", sm: "left" }
            }}
          >
            18+&nbsp;
              <Typography component="span"
                sx={{
                  color: "#fff",
                  fontSize: "14px",
                  textAlign: { xs: "center", sm: "left" }
                }}
              >
                Tous les jeux sur le site ne sont PAS en argent réel.
              </Typography>
          </Typography>
          <List
            sx={{ display: "flex", flexDirection: "row" }}
          >
            {navItems.map((item) => (
              <ListItem key={item?.title} disablePadding>
                <Link to={item?.src} className="nav-link" relative="path">
                    {item?.title}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>

        <Typography sx={{ fontSize: "12px", width: "100%", textAlign: "center", color: "#fff" }}>
          {copyrightText}
        </Typography>
      </Box>
    </footer>
  );
}

export default Footer;
