import img1 from '../../media/fb3be183c5d0411487fdcd592ea05fea-512x384.jpg'
import img2 from '../../media/d7656d887fe04d90869710bad9f74b8a-512x384.jpg'
import img3 from '../../media/64621b259e4e471fa4a99cfa65d09571-512x384.jpg'
import img4 from '../../media/IiwidG9wRG9tYWluIjoiZ2FtZWRpc3RyaWJ1dGlv.jpg'
import img5 from '../../media/qnftmgqxllc2l19j6i9btljfsq6l263bdo23ahdy.jpg'
import img6 from '../../media/8lzd91nuutwmhm7ggrtvp7hc1oq4or18nwnewcbv.jpg'
import img7 from '../../media/iukn5nvr31hwd85dpze5csp9axbekdz5548osxir.jpg'
import img8 from '../../media/r2571itnc02g03cay7pp8em6j5mygy62f3j7fjgo.jpg'
import img9 from '../../media/0aghn40km8dqwoa7769z49k32d683k4pob3ib4el.jpg'
import img10 from '../../media/9900jpyhvhxaia84geujqus0ccrszf2mxko170mi.jpg'
import img11 from '../../media/jhlb6bwe4fikevf1qnftmgqxllc2l19j6i9btljf.jpg'
import img12 from '../../media/sq6l263bdo23ahdy8lzd91nuutwmhm7ggrtvp7hc.jpg'
import img13 from '../../media/1oq4or18nwnewcbviukn5nvr31hwd85dpze5csp9.jpg'
import img14 from '../../media/axbekdz5548osxirr2571itnc02g03cay7pp8em6.jpg'
import img15 from '../../media/j5mygy62f3j7fjgo0aghn40km8dqwoa7769z49k3.jpg'
import img16 from '../../media/2d683k4pob3ib4el9900jpyhvhxaia84geujqus0.jpg'
import img17 from '../../media/ccrszf2mxko170mijhlb6bwe4fikevf1qnftmgqx.jpg'
import img18 from '../../media/llc2l19j6i9btljfsq6l263bdo23ahdy8lzd91nu.jpg'
import img19 from '../../media/utwmhm7ggrtvp7hc1oq4or18nwnewcbviukn5nvr.jpg'
import img20 from '../../media/31hwd85dpze5csp9axbekdz5548osxirr2571itn.jpg'
import img21 from '../../media/c02g03cay7pp8em6j5mygy62f3j7fjgo0aghn40k.jpg'
import img22 from '../../media/m8dqwoa7769z49k32d683k4pob3ib4el9900jpyh.jpg'
import img23 from '../../media/vhxaia84geujqus0ccrszf2mxko170mijhlb6bwe.jpg'
import img24 from '../../media/4fikevf1qnftmgqxllc2l19j6i9btljfsq6l263b.jpg'
import img25 from '../../media/do23ahdy8lzd91nuutwmhm7ggrtvp7hc1oq4or18.jpg'
import img26 from '../../media/nwnewcbviukn5nvr31hwd85dpze5csp9axbekdz5.jpg'
import img27 from '../../media/548osxirr2571itnc02g03cay7pp8em6j5mygy62.jpg'
import img28 from '../../media/f3j7fjgo0aghn40km8dqwoa7769z49k32d683k4p.jpg'
import img29 from '../../media/ob3ib4el9900jpyhvhxaia84geujqus0ccrszf2m.jpg'
import img30 from '../../media/xko170mijhlb6bwe4fikevf1qnftmgqxllc2l19j.jpg'
import img31 from '../../media/6i9btljfsq6l263bdo23ahdy8lzd91nuutwmhm7g.jpg'
import img32 from '../../media/grtvp7hc1oq4or18nwnewcbviukn5nvr31hwd85d.jpg'

const games = [
  {
    id: "fb3be183c5d0411487fdcd592ea05fea",
    title: "Dog Puzzle Story 3",
    category: ['logic', 'popular'],
    image: img1,
    url: 'dog-puzzle-story-3',
    iframe: "https://html5.gamedistribution.com/fb3be183c5d0411487fdcd592ea05fea/?gd_sdk_referrer_url=https://gamedistribution.com/games/dog-puzzle-story-3",
  },
  {
    id: "d7656d887fe04d90869710bad9f74b8a",
    title: "Brawl Hero",
    category: ['logic', 'popular'],
    image: img2,
    url: 'brawl-hero',
    iframe: "https://html5.gamedistribution.com/d7656d887fe04d90869710bad9f74b8a/?gd_sdk_referrer_url=https://gamedistribution.com/games/brawl-hero",
  },
  {
    id: "64621b259e4e471fa4a99cfa65d09571",
    title: "Parking Rush",
    category: ['logic', 'popular'],
    image: img3,
    url: 'parking-rush',
    iframe: "https://html5.gamedistribution.com/64621b259e4e471fa4a99cfa65d09571/?gd_sdk_referrer_url=https://gamedistribution.com/games/parking-rush",
  },
  {
    id: "IiwidG9wRG9tYWluIjoiZ2FtZWRpc3RyaWJ1dGlv",
    title: "gargantoonz",
    category: ['slot'],
    image: img4,
    url: 'gargantoonz',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=gargantoonz&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "qnftmgqxllc2l19j6i9btljfsq6l263bdo23ahdy",
    title: "Piggy Blitz",
    category: ['slot'],
    image: img5,
    url: 'piggy-blitz',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=piggyblitz&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "8lzd91nuutwmhm7ggrtvp7hc1oq4or18nwnewcbv",
    title: "Sweet Alchemy 100",
    category: ['slot'],
    image: img6,
    url: 'sweet-alchemy-100',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=sweetalchemy100&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "iukn5nvr31hwd85dpze5csp9axbekdz5548osxir",
    title: "Boat Bonanza Colossal Catch",
    category: ['slot'],
    image: img7,
    url: 'boat-bonanza-colossal-catch',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=boatbonanza&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "r2571itnc02g03cay7pp8em6j5mygy62f3j7fjgo",
    title: "Fox Mayhem",
    category: ['slot'],
    image: img8,
    url: 'fox-mayhem',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=foxmayhem&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "0aghn40km8dqwoa7769z49k32d683k4pob3ib4el",
    title: "Mega Don",
    category: ['slot'],
    image: img9,
    url: 'mega-don',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=megadon&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "9900jpyhvhxaia84geujqus0ccrszf2mxko170mi",
    title: "craps",
    category: [],
    image: img10,
    url: 'craps',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=craps&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "jhlb6bwe4fikevf1qnftmgqxllc2l19j6i9btljf",
    title: "mini baccarat",
    category: [],
    image: img11,
    url: 'mini-baccarat',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=minibaccarat&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "sq6l263bdo23ahdy8lzd91nuutwmhm7ggrtvp7hc",
    title: "BlackJack MH",
    category: [],
    image: img12,
    url: 'blackjack-mh',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=blackjackmh&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "axbekdz5548osxirr2571itnc02g03cay7pp8em6",
    title: "Super Wheel",
    category: [],
    image: img14,
    url: 'super-wheel',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=superwheel&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "2d683k4pob3ib4el9900jpyhvhxaia84geujqus0",
    title: "Flex Game",
    category: [],
    image: img16,
    url: 'flex-game',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=flexgame&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "ccrszf2mxko170mijhlb6bwe4fikevf1qnftmgqx",
    title: "Park Bingo",
    category: [],
    image: img17,
    url: 'park-bingo',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=parkbingo&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "utwmhm7ggrtvp7hc1oq4or18nwnewcbviukn5nvr",
    title: "Legacy of Dynasties",
    category: ['slot'],
    image: img19,
    url: 'legacy-of-dynasties',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=legacyofdynasties&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "c02g03cay7pp8em6j5mygy62f3j7fjgo0aghn40k",
    title: "Ruff Heist",
    category: ['slot'],
    image: img21,
    url: 'ruff-heist',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ruffheist&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "m8dqwoa7769z49k32d683k4pob3ib4el9900jpyh",
    title: "Rascal Riches",
    category: ['slot'],
    image: img22,
    url: 'rascal-riches',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=rascalriches&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "vhxaia84geujqus0ccrszf2mxko170mijhlb6bwe",
    title: "ternion",
    category: ['slot'],
    image: img23,
    url: 'ternion',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=ternion&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "4fikevf1qnftmgqxllc2l19j6i9btljfsq6l263b",
    title: "slashimi",
    category: ['slot'],
    image: img24,
    url: 'slashimi',
    iframe: "https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&gid=slashimi&lang=en_GB&practice=1&channel=desktop&demo=2",
  },
  {
    id: "do23ahdy8lzd91nuutwmhm7ggrtvp7hc1oq4or18",
    title: "Master Gun",
    category: ['logic'],
    image: img25,
    url: 'master-gun',
    iframe: "https://html5.gamedistribution.com/018a871c73d440b3bf834283ee205404/?gd_sdk_referrer_url=https://gamedistribution.com/games/master-gun",
  },
  {
    id: "nwnewcbviukn5nvr31hwd85dpze5csp9axbekdz5",
    title: "Mad Medicine",
    category: ['logic'],
    image: img26,
    url: 'mad-medicine',
    iframe: "https://html5.gamedistribution.com/a0c133f88f3b493eb011e5ede2ce66c6/?gd_sdk_referrer_url=https://gamedistribution.com/games/mad-medicine",
  },
  {
    id: "f3j7fjgo0aghn40km8dqwoa7769z49k32d683k4p",
    title: "Balls2048",
    category: ['logic'],
    image: img28,
    url: 'balls2048',
    iframe: "https://html5.gamedistribution.com/c643559d4fa74ae9b5b6040a1999c99d/?gd_sdk_referrer_url=https://gamedistribution.com/games/balls2048",
  },
  {
    id: "grtvp7hc1oq4or18nwnewcbviukn5nvr31hwd85d",
    title: "Air Combat 2D",
    category: ['logic'],
    image: img32,
    url: 'air-combat-2d',
    iframe: "https://html5.gamedistribution.com/dc196d3a80804d899c6c347133eb0251/?gd_sdk_referrer_url=https://gamedistribution.com/games/air-combat-2d",
  },
];

export default games.sort(() => 0.5 - Math.random());
