import { Box, Typography } from "@mui/material";
import React from "react";

function DescriptionText() {
  return (
    <Box
      sx={{
        maxWidth: "920px",
        width: "100%",
        background: "#fff",
        m: "80px auto 20px",
        p: "20px 35px",
        borderRadius: "15px",
        textAlign: "left",
        boxSizing: 'border-box',
        color: "#111"
      }}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 900,
          textAlign: "left",
          color: "grey",
          color: "#111"
        }}
      >
        À propos de nous
      </Typography>
      <Typography
        component="h1"
        sx={{
          fontSize: "24px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Pourquoi Choisir Paris Lucky Play ?
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Chez Paris Lucky Play, nous avons soigneusement sélectionné une impressionnante gamme de jeux qui conviennent à tous les goûts et préférences. Que vous soyez un joueur occasionnel à la recherche de divertissement rapide ou un passionné de casino chevronné, notre plateforme a quelque chose d'exceptionnel à vous offrir.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Une Pléthore de Jeux en Ligne Gratuits
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Explorez notre vaste bibliothèque de jeux en ligne gratuits qui couvrent une multitude de genres. Des aventures palpitantes aux casse-têtes stimulants, en passant par les simulations immersives et les classiques préférés, les possibilités sont infinies. Plongez dans un univers de divertissement sans fin sans jamais sortir votre portefeuille.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Des Sensations de Casino sans Risque
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Plongez dans l'excitation des jeux de casino sans la pression financière. Nos jeux de casino offrent l'ambiance authentique d'un véritable casino, avec une large gamme d'options telles que les machines à sous, le poker, le blackjack et la roulette. Jouez pour le plaisir du jeu, pas pour les enjeux.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Sécurité et Équité Garanties
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Votre sécurité et votre jeu équitable sont nos principales priorités. Soyez assuré que tous nos jeux sont minutieusement conçus pour être justes et impartiaux, avec un cryptage conforme aux normes de l'industrie pour protéger vos données. Profitez d'une expérience de jeu sans souci.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Rejoignez Notre Communauté
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Paris Lucky Play est plus qu'une simple plateforme de jeu ; c'est une communauté florissante de joueurs du monde entier. Connectez-vous avec d'autres joueurs, partagez vos expériences et faites de nouveaux amis sur nos forums et nos salles de discussion.
      </Typography>
      <Typography
        component="h2"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          p: "10px 0",
          textAlign: "left",
          color: "#111"
        }}
      >
        Commencez Votre Aventure Aujourd'hui
      </Typography>
      <Typography component="p" sx={{ fontSize: "12px", color: "#111" }}>
        Que vous soyez ici pour un jeu rapide pour vous détendre ou une aventure de casino immersive, Paris Lucky Play est votre billet pour un divertissement sans fin. Rejoignez-nous dès aujourd'hui et découvrez un monde d'excitation à portée de clic.
        <br /><br />
        N'oubliez pas qu'à Paris Lucky Play, il s'agit avant tout de s'amuser, sans les risques liés à l'argent réel. Bienvenue dans un paradis du jeu qui vaut vraiment la peine d'être exploré.
        <br /><br />
        Êtes-vous prêt à jouer, rire et gagner ? Commencez votre aventure avec nous dès maintenant !
      </Typography>
    </Box>
  );
}

export default DescriptionText;
